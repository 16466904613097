import React from "react";
import { navigate } from "gatsby";
import { PageProps } from "gatsby";
import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import { useUserGeojson } from "hooks/api";
import { Table, TableFooter } from "components/common/Table";
import { AdminSidebar } from "components/view/AdminSidebar";
import { MapIDRow } from "components/MapIDRow";
import { formatDate } from "utils/format";

interface MapsPageProps extends PageProps {}

export default function MapsPage(props: MapsPageProps) {
  const { token } = useAuth();
  const [sortDate, setSortDate] = React.useState("");
  const [sortName, setSortName] = React.useState("");
  const userGeojson = useUserGeojson("", sortDate, sortName);

  const onSortName = React.useCallback((sortAsc: boolean) => {
    sortAsc ? setSortName("asc") : setSortName("desc");
  }, []);

  const onSortDate = React.useCallback((sortAsc: boolean) => {
    sortAsc ? setSortDate("asc") : setSortDate("desc");
    setSortName("");
  }, []);

  const handleRowClick = React.useCallback((mapId) => {
    navigate("/administration/builder", {
      state: {
        mapId,
      },
    });
  }, []);

  const handleDeleteClick = React.useCallback((mapId) => {
    userGeojson.removeUserGeojson(mapId);
  }, [userGeojson.removeUserGeojson]);

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  return (
    <Layout backgroundMap={true}>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <Table
            data={userGeojson.data}
            pageLength={10}
            onRowClick={handleRowClick}
            columns={[
              {
                title: "Name",
                accesor: "name",
                sort: (sortAsc: boolean) => onSortName(sortAsc),
              },
              {
                title: "Creation Date",
                accesor: "created_at",
                render: (_, row) => formatDate(row.created_at),
                sort: (sortAsc: boolean) => onSortDate(sortAsc),
              },
              {
                title: "Map ID",
                accesor: "id",
                render: (_, row) => (
                  <MapIDRow
                    id={row.id}
                    handleRowClick={handleRowClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                ),
                width: "45%",
              },
            ]}
          >
            <TableFooter
              pageLength={10}
              page={Number(userGeojson.pagination?.page) || 0}
              totalRecords={Number(userGeojson.pagination?.totalRecords) || 0}
              onPageChange={userGeojson.onChangePage}
            />
          </Table>
        </Container>
      </Container>
    </Layout>
  );
}
