import * as React from "react";
import { copyTextToClipboard } from "store/utils";
import { ClipboardIcon, FreehandIcon, TrashIcon } from "components/Icons";
import {
  row,
  text,
  actionButton,
  buttonsContainer,
  popover,
  show,
  hide,
  noFill,
} from "./MapIDRow.module.scss";
import { API_URL } from "settings";
import { className } from "utils/className";

interface MapIDRowProps {
  id: string;
  handleRowClick: (arg: string) => void;
  handleDeleteClick: (id: String) => void;
}

export const MapIDRow = (props: MapIDRowProps) => {
  const { id, handleRowClick, handleDeleteClick } = props;
  const [copied, setCopied] = React.useState(false);

  const handleCopyToClipboard = React.useCallback(
    (e) => {
      e.stopPropagation();
      copyTextToClipboard(`${API_URL}v1/custom-map/${id}` || "").then(
        (success) => {
          if (success) {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 750);
          }
        }
      );
    },
    [id]
  );

  const handleConfirmDelete = React.useCallback(
    (e) => {
      e.stopPropagation();
      const confirmed = confirm("Are you sure you want to delete this map?");
      if (confirmed) handleDeleteClick(id);
    },
    [handleDeleteClick, id]
  );

  return (
    <div className={row}>
      <span className={text} onClick={handleCopyToClipboard}>
        {id}
      </span>
      <div className={buttonsContainer}>
        <button
          className={className(actionButton, noFill)}
          aria-label="Copy to clipboard"
          onClick={handleCopyToClipboard}
        >
          <ClipboardIcon />
          <span className={`${popover} ${copied ? show : hide}`}>Copied!</span>
        </button>
        <button
          className={actionButton}
          aria-label="Edit map"
          onClick={() => handleRowClick(id)}
        >
          <FreehandIcon />
        </button>
        <button
          className={actionButton}
          aria-label="Delete map"
          onClick={handleConfirmDelete}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
